import React, { useEffect, useState, useRef } from 'react'

import mxxn from "../images/mxxn.png"
import row1 from "../images/row1.jpg"
import row2 from "../images/row2.jpg"
import music from "../music/beat.mp3"

import Helmet from "react-helmet";

import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { RiGhost2Fill } from 'react-icons/ri';
import { BsFillArrowDownSquareFill, BsFillArrowUpSquareFill, BsFillVolumeUpFill, BsVolumeMuteFill } from 'react-icons/bs';

export default function IndexPage() {
  const [arrow, setArrow] = useState('down');
  const [isMusicPaused, setIsMusicPaused] = useState(false);
  const main = useRef();
  const musicplayer = useRef();

  useEffect(() => {
    if (!musicplayer) {
      return;
    }
    setIsMusicPaused(musicplayer.current.paused)
  }, [musicplayer])

  function toggleMusic() {
    if (isMusicPaused) {
      musicplayer.current.play();
      setIsMusicPaused(false);
    } else {

      musicplayer.current.pause();
      setIsMusicPaused(true);
    }
  }

  function updatePosition(e) {
    if (e.currentTarget.scrollTop > 1.5 * window.innerHeight) {
      setArrow('up')
    } else {
      setArrow('down')
    }
  }

  function scrollTo(e) {
    if (main.current.scrollTop < window.innerHeight) {
      // page 0 -> 1
      main.current.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
    } else if (main.current.scrollTop >= window.innerHeight && main.current.scrollTop < 1.5* window.innerHeight) {
      // page 1 -> 2
      main.current.scrollTo({ top: 2*window.innerHeight, behavior: 'smooth' })
    } else {
      // page 2 -> 1
      main.current.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
    }
  }

  return (
    <>
      <Helmet title="MXXN WRLD" defer={false}>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Helmet>
      <nav className="container flex justify-around p-8 mx-auto fixed max-w-full font-titan text-xl text-primary">
        <div className="flex text-4xl">
          <a rel="noreferrer" className="mx-4 box-border text-primary" href="https://discord.gg/mxxnwrld" target="_blank"><FaDiscord /></a>
          <a rel="noreferrer" className="mx-4 box-border text-primary" href="https://twitter.com/MXXN_WRLD" target="_blank"><FaTwitter /></a>
        </div>
        <div className="grow h-14" />
        <div className="flex text-4xl">
          <RiGhost2Fill className="mx-4 box-border" />
        </div>
      </nav>
      <nav className="container flex justify-around p-8 mx-auto fixed bottom-0 max-w-full place-items-end font-titan text-xl text-primary">
        <div className="flex-none">
          {isMusicPaused
            ? <div className="cursor-pointer" onClick={toggleMusic}><BsVolumeMuteFill className="inline text-2xl" /> SOUND</div>
            : <div className="cursor-pointer" onClick={toggleMusic}><BsFillVolumeUpFill className="inline text-2xl" /> SOUND</div>}
          <audio ref={musicplayer} autoPlay loop><source src={music} /></audio>
        </div>
        <div className="grow text-center">
          {arrow === 'down' ? <BsFillArrowDownSquareFill className="inline text-2xl cursor-pointer" onClick={scrollTo} /> : <BsFillArrowUpSquareFill className="inline text-2xl cursor-pointer" onClick={scrollTo} />}
        </div>
        <div className="flex-none hidden lg:inline">
          2022 MXXN WRLD
        </div>
        <div className="flex-none inline lg:hidden">
          2022 MXW
        </div>
      </nav>
      <main className="max-h-screen overflow-y-scroll snap snap-y snap-mandatory" onScroll={updatePosition} ref={main}>
        <section className="flex items-center justify-center w-full h-screen  bg-center bg-cover snap-start">
          <div>
            <img alt="logo" src={mxxn} className="block max-w-xs md:max-w-md	max-h-md m-auto" />
            <p className="text-primary font-titan text-6xl md:text-8xl text-center">MXXN WRLD</p>
          </div>
        </section>

        <section className="flex items-center justify-center w-full h-screen snap-start">
          <div className="flex items-center justify-center gap-8 flex-wrap">
            <div className="text-black font-merriweather text-4xl xl:text-6xl flex items-center justify-center">
              MXXN WRLD.<br />
              More than just art.
            </div>
            <div>
            <img alt="img1" src={row1} style={{borderRadius:'40px'}} className="block max-w-xs md:max-w-md xl:max-w-2xl	max-h-md m-auto" />
            </div>
          </div>
        </section>

        <section className="flex items-center justify-center w-full h-screen snap-start">
          <div className="flex items-center justify-center gap-8 flex-wrap-reverse lg:flex-wrap-reverse">
            <div className="">
              <a href="https://dean3lr.gitbook.io/moonbook/" target="_blank" rel="noreferrer">
                <img alt="img2" style={{borderRadius:'20px'}} src={row2} className="block max-w-xs md:max-w-md	max-h-md m-auto" />
              </a>
            </div>
            <div className="text-black font-merriweather text-3xl xl:text-6xl flex items-center justify-center">
              Learn more.<br />
              Read the MXXN Book.
            </div>
          </div>
        </section>
      </main>

    </>
  )
}
